<template>
    <div id="monitoringcloud">
        <public-header class="public-header"></public-header>
        <div class="main-content flex">
            <left-menu
                v-if="lyjActiveNum != '3'"
                class="left-menu"
                :menus="menus"
                :mainMenuIndex="mainMenuIndex"
                @changeMenu="changeMenu"
            ></left-menu>
            <div class="flex-1">
                <router-view class="main"></router-view>
            </div>
            <div
                class="system2Part"
                v-if="lyj_inspect_state && lyjActiveNum == 1"
            >
                <Xxgltj></Xxgltj>
            </div>
            <div class="fxtjBtn">
                <img
                    src="@image/lyj/icon/change.png"
                    title="巡查统计"
                    v-if="
                        system == 'system2' &&
                        !lyj_inspect_state &&
                        lyjActiveNum != '3'
                    "
                    @click="getFxtjPanel()"
                />
            </div>
        </div>
    </div>
</template>
<script>
import info_mixin from "./info_mixin";
import Xxgltj from "@views/lyj/inspect_admin/index"; //巡查管理 统计
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    mixins: [info_mixin],
    name: "monitoringcloud",
    components: {
        Xxgltj,
    },
    computed: {
        ...mapGetters(["system", "lyj_inspect_state", "lyjActiveNum"]),
    },
    methods: {
        ...mapMutations({
            SET_LYJ_XCGL: "SET_LYJ_XCGL",
        }),
        getFxtjPanel() {
            this.SET_LYJ_XCGL({
                lyj_inspect_state: true,
            });
        },
    },
};
</script>
<style lang="scss" scoped>
#monitoringcloud {
    height: 100%;
    width: 100%;
    position: relative;
    .main-content {
        height: calc(100% - 80px);
        background: #eaece6;
        position: relative;
        .main {
            height: calc(100% - 30px);
            background-color: #f7f9f4;
        }
        .system2Part {
            width: 100%;
            height: 100%;
            background-color: #f7f9f4;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
        }
        .fxtjBtn {
            position: absolute;
            right: 20px;
            top: -2px;
            cursor: pointer;
        }
    }
}
</style>
