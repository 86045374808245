import PublicHeader from "@comp/public_header.vue";
import LeftMenu from "@comp/left_menu.vue";
import { mapActions } from "vuex";

import jcxList from "../task_manage/jcx_list.js";
export default {
    name: "monitoringcloud",
    components: {
        LeftMenu,
        PublicHeader,
    },
    data() {
        return {
            jcxList: jcxList,
            menus: [{
                id: "2001",
                name: "任务管理",
                routerName: "taskManage",
                // bgPicture: "menu/task-manage"
                className: "icon-renwuguanli-01",
            },
            {
                id: "2002",
                name: "异常预警",
                routerName: "abnormalWarning",
                // bgPicture: "menu/abnormal-warning"
                className: "icon-yichanyujing-01",
            },
            {
                id: "2003",
                name: "任务执行",
                routerName: "taskExecution",
                // bgPicture: "menu/task-execution"
                className: "icon-renwuzhihang-01",
            },
            {
                id: "2004",
                name: "人员轨迹",
                routerName: "peopleLocus",
                // bgPicture: "menu/people-locus"
                className: "icon-renyuanguiji-01",
            },
            {
                id: "2005",
                name: "数据统计",
                routerName: "dataStatistics",
                // bgPicture: "menu/data-statistics"
                className: "icon-shujutongji-01",
            },
            {
                id: '2006',
                name: "任务日志",
                routerName: "taskLog",
                // bgPicture: "menu/data-statistics"
                className: "icon-rizhiguanli"
            },
            {
                id: '2007',
                name: "森林护林员",
                routerName: "forestPeople",
                // bgPicture: "menu/data-statistics"
                className: "icon-anquanmaojishuyuangongchengshi"
            },
            {
                id: '2008',
                name: "巡查报告",
                routerName: "patrolReport",
                // bgPicture: "menu/data-statistics"
                className: "icon-anquanmaojishuyuangongchengshi"
            },
            ],
            mainMenuIndex: "20",
        };
    },
    watch: {
        $route: {
            handler(val, oldVal) {
                let isexclude = "";
                if (val.name === "taskExecution") {
                    isexclude = "";
                } else {
                    isexclude = "1";
                }
                this.getJcxBasicList(isexclude);
            },
            immediate: true,
            deep: true,
        },
    },
    mounted() { },
    created() { },
    methods: {
        ...mapActions(["GetJCXMC"]),
        changeMenu(menu, index) {
            this.getJcxBasicList();
        },
        // 获取监测项列表
        async getJcxBasicList(isexclude) {
            localStorage.jcxList = "";
            let res = await this.GetJCXMC({
                isexclude: isexclude,
            });
            // 获取监测项列表，统一处理
            let da = res.filter(item => item.sfzs === '1');
            // 新增下拉列表
            da.forEach((item) => {
                item.label = item.name;
                item.value = Number(item.value);
                this.jcxList.map((item1) => {
                    if (item1.key == item.value) {
                        item.path = item1.path;
                    }
                })
            });
            localStorage.jcxList = JSON.stringify(da);
        },
    },
};