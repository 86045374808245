<template>
    <div class="noMonitor">
        {{text}}
    </div>
</template>
<script>
export default {
    name: "",
    components: {},
    props: {
        text: {
            type: String,
            default: "暂未监测"
        }
    },
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {}
};
</script>
<style scoped>
.noMonitor {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-30deg);
    font-size: 24px;
    color: rgb(69, 112, 141);
    z-index: 2000;
}
@media screen and (max-width: 1500px) {
}
</style>
