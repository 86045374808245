<template>
    <div class="mainPart">
        <!-- 中间 -->
        <div class="topPart flex-between">
            <div class="topItem">
                <div class="title">工作情况</div>
                <div id="gzqk" class="echart"></div>
            </div>
            <div class="topItem flex-1" style="margin-left: 10px">
                <div class="title">采集人执行情况</div>
                <div id="cjrzxqk" class="echart"></div>
            </div>

            <el-button type="primary" class="btnPart" @click="goItem()"
                >巡查管理</el-button
            >
        </div>
        <!-- 底部 -->
        <div class="bottomPart">
            <div class="title">工作量统计</div>
            <div class="flex bottomCtn">
                <div class="echart" id="jcshtj"></div>
                <div class="flex-y-center flex-1">
                    <div class="bottomItem">
                        <div class="name">日常巡查</div>
                        <div class="flex-between">
                            <div class="desc">
                                负责片区：{{ rcxcPart.pqNum }}
                            </div>
                            <div class="desc">
                                巡查点位：{{ rcxcPart.dwNum }}个
                            </div>
                        </div>
                        <div class="flex-between-y-center echartPart">
                            <div class="time">本期已完成巡查：</div>
                            <div class="echartCtn">
                                <div class="echart" id="rcxcEchart"></div>
                                <NoMonitor
                                    v-if="!rcxcPart.totalNum"
                                    text="暂无巡查"
                                ></NoMonitor>
                            </div>
                            <div class="echart" id="rcxcEchart"></div>
                        </div>
                    </div>

                    <div class="bottomItem">
                        <div class="name">异常跟踪</div>
                        <div class="desc">
                            未解除异常：{{ ycgzPart.wjcNum }}
                        </div>
                        <div class="flex-between-y-center echartPart">
                            <div class="time">本期已完成巡查：</div>
                            <div class="echartCtn">
                                <div class="echart" id="ycgzEchart"></div>
                                <NoMonitor
                                    v-if="!ycgzPart.totalNum"
                                    text="暂无巡查"
                                ></NoMonitor>
                            </div>
                        </div>
                    </div>

                    <div class="bottomItem">
                        <div class="name">林业项目</div>
                        <div class="flex-between">
                            <div class="desc">
                                进行中项目：{{ lyxmPart.jxzNum }}
                            </div>
                            <div class="desc">
                                监测点位：{{ lyxmPart.dwNum }}个
                            </div>
                        </div>
                        <div class="flex-between-y-center echartPart">
                            <div class="time">本期已完成巡查：</div>
                            <div class="echartCtn">
                                <div class="echart" id="lyxmEchart"></div>
                                <NoMonitor
                                    v-if="!lyxmPart.totalNum"
                                    text="暂无巡查"
                                ></NoMonitor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import NoMonitor from "@views/big_screen/comp/NoMonitor";
export default {
    components: {
        NoMonitor,
    },
    name: "",
    data() {
        return {
            rcxcPart: {
                pqNum: 0,
                dwNum: 0,
                echart: null,
                percent: 0,
                totalNum: 0,
                list: [
                    {
                        name: "已完成",
                        value: 0,
                    },
                    {
                        name: "未完成",
                        value: 0,
                    },
                ],
            },
            ycgzPart: {
                wjcNum: 0,
                dwNum: 0,
                echart: null,
                percent: 0,
                totalNum: 0,
                list: [
                    {
                        name: "已完成",
                        value: 0,
                    },
                    {
                        name: "未完成",
                        value: 0,
                    },
                ],
            },
            lyxmPart: {
                jxzNum: 0,
                dwNum: 0,
                percent: 0,
                totalNum: 0,
                list: [
                    {
                        name: "已完成",
                        value: 0,
                    },
                    {
                        name: "未完成",
                        value: 0,
                    },
                ],
            },
        };
    },
    mounted() {
        this.getTopData();
        this.getBottomData();
    },
    methods: {
        ...mapMutations({
            SET_LYJ_XCGL: "SET_LYJ_XCGL",
        }),
        ...mapActions(["GetTaskStatisticForLyj", "GetWorkLoadStatistic"]),
        goItem() {
            this.SET_LYJ_XCGL({
                lyj_inspect_state: false,
            });
        },
        async getTopData() {
            let result = await this.GetTaskStatisticForLyj({});
            this.initEchartCjrzxqk(result.RESULTVALUE.ALLCOLLECTOR);
            this.initEchartGzqk(result.RESULTVALUE.ALLTASK);
        },
        // 工作情况
        initEchartGzqk(list) {
            let colorList = ["#ffcd5e", "#ff5599", "#4189ff", "#ffcd5e"];
            let subtext = 0;
            let seriesData = [];
            list.map((item) => {
                subtext += item.VALUE;
                let obj = {
                    name: item.RWZTMC,
                    value: item.VALUE,
                };
                seriesData.push(obj);
            });
            let option = {
                title: {
                    text: "任务总数",
                    subtext: subtext,
                    left: "center",
                    top: "42%",
                    textStyle: {
                        fontSize: 23,
                        color: "rgba(74,90,127,1)",
                    },
                    subtextStyle: {
                        fontSize: 23,
                        color: "rgba(74,90,127,1)",
                    },
                },
                grid: {
                    left: 0,
                    right: 0,
                    top: 100,
                },
                color: colorList,
                series: {
                    type: "pie",
                    hoverAnimation: false,
                    radius: [60, 80],
                    label: {
                        formatter: (e) => {
                            let str = `${e.data.name}\n${e.data.value}个${e.percent}%`;
                            return str;
                        },
                        position: "outside", //显示在扇形上
                    },
                    labelLine: {
                        normal: {
                            length: 15,
                            length2: 80,
                            maxSurfaceAngle: 80,
                        },
                    },
                    data: seriesData,
                },
            };
            this.gzqk = this.$echarts.dispose(document.getElementById("gzqk"));
            this.gzqk = this.$echarts.init(document.getElementById("gzqk"));
            this.gzqk.setOption(option);
        },
        // 采集人执行情况
        initEchartCjrzxqk(list) {
            let xAxisData = [];
            let seriesDataYgq = []; //已过期
            let seriesDataYwc = []; //已完成
            let seriesDataWwc = []; //未完成

            list.map((item) => {
                xAxisData.push(item.CJRMC);
                seriesDataYgq.push(item.YGQSL);
                seriesDataYwc.push(item.YWCSL);
                seriesDataWwc.push(item.WWCSL);
            });

            let option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "none", // 'shadow' as default; can also be 'line' or 'shadow'
                    },
                    formatter(list) {
                        let str = `<div style="text-align:center;borer-bottom:1px solid #fff;">${list[0].axisValue}<br></div>`;
                        list.map((item) => {
                            let color = "";
                            switch (item.seriesName) {
                                case "未完成":
                                    color = "#F34D4E";
                                    break;
                                case "已过期":
                                    color = "#428AF5";
                                    break;
                                case "已完成":
                                    color = "#25D096";
                                    break;
                            }

                            let marker = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color};"></span>`;
                            str += `${marker} ${item.seriesName}：  ${item.value}个<br>`;
                        });
                        return str;
                    },
                },
                grid: {
                    left: 20,
                    right: 80,
                    bottom: 20,
                    containLabel: true,
                },
                xAxis: {
                    name: "采集人员",
                    type: "category",
                    data: xAxisData,
                },
                yAxis: {
                    name: "个",
                    type: "value",
                },

                series: [
                    {
                        name: "已过期",
                        type: "bar",
                        stack: "total",
                        data: seriesDataYgq,
                        itemStyle: {
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    { offset: 0, color: "#60C5FF" },
                                    { offset: 1, color: "#428AF5" },
                                ]
                            ),
                        },
                    },

                    {
                        name: "已完成",
                        type: "bar",
                        stack: "total",
                        data: seriesDataYwc,
                        color: "#428AF5",
                        itemStyle: {
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    { offset: 0, color: "#7CFACF" },
                                    { offset: 1, color: "#25D096" },
                                ]
                            ),
                        },
                    },
                    {
                        name: "未完成",
                        barWidth: "42",
                        type: "bar",
                        stack: "total",
                        data: seriesDataWwc,
                        itemStyle: {
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    { offset: 0, color: "#FA9293" },
                                    { offset: 1, color: "#F34D4E" },
                                ]
                            ),
                        },
                    },
                ],
            };
            this.cjrzxqk = this.$echarts.dispose(
                document.getElementById("cjrzxqk")
            );
            this.cjrzxqk = this.$echarts.init(
                document.getElementById("cjrzxqk")
            );
            this.cjrzxqk.setOption(option);
        },
        async getBottomData() {
            let result = await this.GetWorkLoadStatistic({});
            console.log(
                "%c 🍐 GetWorkLoadStatistic: ",
                "font-size:20px;background-color: #465975;color:#fff;"
            );

            // 林业项目 0 个
            this.lyxmPart.totalNum = result.RESULTVALUE.LYJJCXINFOS[0].ALLCOUNT;
            this.lyxmPart.wjcNum = result.RESULTVALUE.LYJJCXINFOS[0].COUNT;
            this.lyxmPart.dwNum = result.RESULTVALUE.LYJJCXINFOS[0].DWCOUNT;
            this.lyxmPart.list[0].value =
                result.RESULTVALUE.LYJJCXINFOS[0].FINISHCOUNT;
            this.lyxmPart.list[1].value =
                result.RESULTVALUE.LYJJCXINFOS[0].ALLCOUNT;
            if (result.RESULTVALUE.LYJJCXINFOS[0].ALLCOUNT == 0) {
                this.lyxmPart.percent = 0;
            } else {
                this.lyxmPart.percent =
                    (result.RESULTVALUE.LYJJCXINFOS[0].FINISHCOUNT /
                        result.RESULTVALUE.LYJJCXINFOS[0].ALLCOUNT) *
                    100;
            }
            // 日常巡查 第一个
            this.rcxcPart.totalNum = result.RESULTVALUE.LYJJCXINFOS[1].ALLCOUNT;
            this.rcxcPart.pqNum = result.RESULTVALUE.LYJJCXINFOS[1].COUNT;
            this.rcxcPart.dwNum = result.RESULTVALUE.LYJJCXINFOS[1].DWCOUNT;
            this.rcxcPart.list[0].value =
                result.RESULTVALUE.LYJJCXINFOS[1].FINISHCOUNT;
            this.rcxcPart.list[1].value =
                result.RESULTVALUE.LYJJCXINFOS[1].ALLCOUNT;
            if (result.RESULTVALUE.LYJJCXINFOS[1].ALLCOUNT == 0) {
                this.rcxcPart.percent = 0;
            } else {
                this.rcxcPart.percent =
                    (result.RESULTVALUE.LYJJCXINFOS[1].FINISHCOUNT /
                        result.RESULTVALUE.LYJJCXINFOS[1].ALLCOUNT) *
                    100;
            }
            //异常预警 第三个
            this.ycgzPart.totalNum = result.RESULTVALUE.LYJJCXINFOS[0].ALLCOUNT;
            this.ycgzPart.jxzNum = result.RESULTVALUE.LYJJCXINFOS[0].COUNT;
            this.ycgzPart.dwNum = result.RESULTVALUE.LYJJCXINFOS[0].DWCOUNT;
            this.ycgzPart.list[0].value =
                result.RESULTVALUE.LYJJCXINFOS[0].FINISHCOUNT;
            this.ycgzPart.list[1].value =
                result.RESULTVALUE.LYJJCXINFOS[0].ALLCOUNT;

            if (result.RESULTVALUE.LYJJCXINFOS[0].ALLCOUNT == 0) {
                this.ycgzPart.percent = 0;
            } else {
                this.ycgzPart.percent =
                    (result.RESULTVALUE.LYJJCXINFOS[0].FINISHCOUNT /
                        result.RESULTVALUE.LYJJCXINFOS[0].ALLCOUNT) *
                    100;
            }

            this.initEchartGzltj(result.RESULTVALUE.ALLWORKLOADSTATE.JCXS);
            this.initEchartRcxc(
                "rcxcEchart",
                this.rcxcPart.list,
                this.rcxcPart.percent,
                this.rcxcPart.totalNum,
                this.rcxcPart.echart
            );
            this.initEchartRcxc(
                "ycgzEchart",
                this.ycgzPart.list,
                this.ycgzPart.percent,
                this.ycgzPart.totalNum,
                this.ycgzPart.echart
            );
            this.initEchartRcxc(
                "lyxmEchart",
                this.lyxmPart.list,
                this.lyxmPart.percent,
                this.lyxmPart.totalNum,
                this.lyxmPart.echart
            );
        },
        // 工作量统计
        initEchartGzltj(list) {
            let seriesData = [];
            let total = 0;
            list.map((item) => {
                let obj = {
                    name: item.JCX,
                    value: item.COUNT,
                };
                total += item.COUNT;
                seriesData.push(obj);
            });
            let colorList = ["#ffcd5e", "#ff5599", "#4189ff", "#ffcd5e"];
            let option = {
                title: {
                    text: "任务总数",
                    subtext: total,
                    left: "center",
                    top: "42%",
                    textStyle: {
                        fontSize: 23,
                        color: "rgba(74,90,127,1)",
                    },
                    subtextStyle: {
                        fontSize: 23,
                        color: "rgba(74,90,127,1)",
                    },
                },
                grid: {
                    left: 0,
                    right: 0,
                },
                color: colorList,
                series: {
                    type: "pie",
                    hoverAnimation: false,
                    radius: [60, 80],
                    label: {
                        formatter: (e) => {
                            let str = `${e.data.name}\n${e.data.value}个${e.percent}%`;
                            return str;
                        },
                        position: "outside", //显示在扇形上
                    },
                    labelLine: {
                        normal: {
                            length: 15,
                            length2: 80,
                            maxSurfaceAngle: 80,
                        },
                    },
                    data: seriesData,
                },
            };
            this.jcshtj = this.$echarts.dispose(
                document.getElementById("jcshtj")
            );
            this.jcshtj = this.$echarts.init(document.getElementById("jcshtj"));
            this.jcshtj.setOption(option);
        },
        // 日常巡查 + 异常跟踪 + 林业项目
        initEchartRcxc(id, list, percent, totalNum, echart) {
            let option = {
                title: {
                    text: `${percent}%`,
                    left: "center",
                    top: "42%",
                    textStyle: {
                        fontSize: 23,
                        color: "rgba(74,90,127,1)",
                    },
                },
                tooltip: {
                    formatter(item) {
                        let str = `${item.marker}${item.name}<br>${item.value}个(${item.percent}%)`;
                        return str;
                    },
                },
                series: [
                    {
                        name: "",
                        type: "pie",
                        radius: ["80%", "100%"],
                        avoidLabelOverlap: false,
                        hoverAnimation: false,
                        label: {
                            show: false,
                            position: "center",
                        },
                        labelLine: {
                            show: false,
                        },
                        color: ["#4089FF", "#FF5599"],
                        data: list,
                    },
                ],
            };
            echart = this.$echarts.dispose(document.getElementById(id));
            if (totalNum) {
                echart = this.$echarts.init(document.getElementById(id));
                echart.setOption(option);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.mainPart {
    padding: 30px 27px;
    background: #eef5fd;
    height: 100%;
    .topPart {
        width: 100%;
        height: 380px;
        position: relative;
        .topItem {
            padding: 0 10px;
            background-color: #fff;
            &:nth-child(1) {
                width: 38%;
            }
            .title {
                font-size: 26px;
                color: rgba(35, 45, 80, 1);
                border-bottom: 1px solid #ecedf1;
                padding: 10px 0;
            }
            .echart {
                height: calc(100% - 60px);
            }
        }
        .btnPart {
            height: 32px;
            min-width: 82px;
            line-height: 33px;
            text-align: center;
            padding: 0 10px;
            background-color: #05a28f;
            color: #fff;
            border-radius: 10px;
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
    .bottomPart {
        height: 424px;
        margin-top: 10px;
        position: relative;
        background-color: #fff;
        box-shadow: 0 0 16px rgba(22, 115, 255, 0.1);
        padding: 0 10px;
        .title {
            font-size: 26px;
            color: rgba(35, 45, 80, 1);
            border-bottom: 1px solid #ecedf1;
            padding: 10px 0;
        }
        .bottomCtn {
            width: 100%;
            height: calc(100% - 60px);

            .echart {
                width: 30%;
                height: 100%;
            }
            .bottomItem {
                width: 300px;
                height: 240px;
                box-shadow: 0 0 16px rgba(22, 115, 255, 0.1);
                padding: 0 10px;
                position: relative;
                margin-right: 10px;
                .name {
                    font-size: 24px;
                    color: rgba(51, 51, 51, 1);
                    border-bottom: 1px solid rgba(236, 237, 241, 1);
                    height: 56px;
                    line-height: 56px;
                }
                .desc {
                    margin-top: 10px;
                    font-size: 18px;
                    color: rgba(153, 153, 153, 1);
                }

                .echartPart {
                    width: 100%;
                    margin-top: 10px;
                    .time {
                        font-size: 18px;
                        color: rgba(153, 153, 153, 1);
                    }
                    .echartCtn {
                        position: relative;
                        width: 126px;
                        height: 126px;
                        .echart {
                            width: 126px;
                            height: 126px;
                        }
                    }
                }
            }
        }
    }
}
</style>
