<template>
    <div id="monitoringcloud">
        <left-menu
            class="left-menu"
            :menus="menus"
            :mainMenuIndex="mainMenuIndex"
            @changeMenu="changeMenu"
        ></left-menu>
        <div class="main-content">
            <public-header class="public-header"></public-header>
            <router-view class="main"></router-view>
        </div>
    </div>
</template>
<script>
import info_mixin from "./info_mixin";
export default {
    mixins: [info_mixin],
    name: "monitoringcloud",
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>
<style lang="scss" scoped>
#monitoringcloud {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    .main-content {
        flex: auto;
        display: flex;
        flex-flow: column nowrap;
        background: #eaece6;
        .main {
            height: calc(100% - 30px);
            background-color: #f7f9f4;
        }
    }
}
</style>
